import axios from 'axios';
import { getCookie } from 'modules/cookie/getCookie';
import { EType } from 'modules/environment/enums/EEnviromentType';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { apiTokenName } from 'modules/session/constants/apiTokenName';
import { getEnviromentType } from 'modules/environment/getEnviromentType';
import { clientTokenName } from 'modules/session/constants/clientTokenName';
import {
    AdsApi,
    SessionApi,
    NotificationEmailApi,
    RealEstateLocationApi,
    RealEstateStatsUserApi,
    RealEstateAgentListingApi,
    RealEstateAgencyDetailApi,
    RealEstateAgencyListingApi,
    RealEstateAgentLandingPageApi,
    RealEstateAgencyLandingPageApi,
    AdvertisementProjectsSearchMapApi as DevProjectsSearchMapApi,
    AdvertisementDetailApi,
    AdvertisementListingApi,
    SearchFilterApi,
    LocationApi,
    AdvertisementAmenitiesApi,
    DevProjectExclusiveProjectListingApi,
    RealEstateAgentDetailApi,
    RealEstateAdvertisementDetailListingApi,
    AdvertisementComparisonApi,
    AdvertisementFavouriteApi,
    RelationApi,
    HomePageDevProjectsApi,
    AdvertisementSearchAgentApi,
    NotificationCenterApi,
} from 'modules/api/generated';

const localInstance = axios.create();

if (getEnviromentType() === EType.FRONT_END) {
    const token = getCookie(clientTokenName);

    localInstance.interceptors.request.use(config => ({
        ...config,
        headers: {
            ...config.headers,
            ...(token && { [apiTokenName]: token }),
        },
        withCredentials: false,
    }));

} else {
    localInstance.interceptors.request.use(config => ({
        ...config,
        withCredentials: false,
    }));
}

const adsApi = new AdsApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const sessionApi = new SessionApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const notificationEmailApi = new NotificationEmailApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateLocationApi = new RealEstateLocationApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateStatsUserApi = new RealEstateStatsUserApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateAgentDetailApi = new RealEstateAgentDetailApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateAgentListingApi = new RealEstateAgentListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateAgencyDetailApi = new RealEstateAgencyDetailApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateAgencyListingApi = new RealEstateAgencyListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const devProjectsSearchMapApi = new DevProjectsSearchMapApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateAgentLandingPageApi = new RealEstateAgentLandingPageApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateAgencyLandingPageApi = new RealEstateAgencyLandingPageApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const realEstateAdvertisementDetailListingApi = new RealEstateAdvertisementDetailListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const advertisementDetailApi = new AdvertisementDetailApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const advertisementListingApi = new AdvertisementListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const searchFilterApi = new SearchFilterApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const locationApi = new LocationApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const advertisementAmenitiesApi = new AdvertisementAmenitiesApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const advertisementFavoriteApi = new AdvertisementFavouriteApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const advertisementComparisonApi = new AdvertisementComparisonApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const devProjectExclusiveProjectListingApi = new DevProjectExclusiveProjectListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const relationApi = new RelationApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const homePageDevProjectsApi = new HomePageDevProjectsApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const searchAgentApi = new AdvertisementSearchAgentApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);
const notificationCenterApi = new NotificationCenterApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), localInstance);

export {
    adsApi,
    sessionApi,
    notificationEmailApi,
    devProjectsSearchMapApi,
    realEstateLocationApi,
    realEstateStatsUserApi,
    realEstateAgentListingApi,
    realEstateAgencyDetailApi,
    realEstateAgencyListingApi,
    realEstateAgentLandingPageApi,
    realEstateAgencyLandingPageApi,
    realEstateAdvertisementDetailListingApi,
    advertisementDetailApi,
    advertisementListingApi,
    locationApi,
    searchFilterApi,
    advertisementAmenitiesApi,
    devProjectExclusiveProjectListingApi,
    advertisementComparisonApi,
    realEstateAgentDetailApi,
    advertisementFavoriteApi,
    relationApi,
    homePageDevProjectsApi,
    searchAgentApi,
    notificationCenterApi,
};


